:root {
  --logo-width: 150px;
  --logo-padding: calc(((var(--nav) - var(--padding)) - var(--logo-width)) / 2);
  --padding: 1rem;
  --half-padding: calc(var(--padding) / 2);
  --nav: 25%;
  --tablet-section: 75%;
  --desktop-section: 50%;

  --light: var(--default-l);
  --dark: var(--default-d);

  /* Default theme */
  --default-l: #eff9ff;
  --default-d: #1a3546;

  /* Jan waxes/ Feb wanes */
  --january-l: #fefcff;
  --january-d: #0e0034;

  /* Feb waxes/ Mar wanes */
  --february-l: #fffff3;
  --february-d: #3f534a;

  /* Mar waxes/ Apr wanes */
  --march-l: #8fc9ff;
  --march-d: #003709;

  /* Apr waxes/ May wanes */
  --april-l: #FFE3F2;
  --april-d: #13C468;

  /* May waxes/ Jun wanes */
  --may-l: #FFF1E6;
  --may-d: #00C2FF;

  /* Jun waxes/ Jul wanes */
  --june-l: #FFF965;
  --june-d: #5135FF;

  /* Jul waxes/ Aug wanes */
  --july-l: #ffdc62;
  --july-d: #c90000;

  /* Aug waxes/ Sep wanes */
  --august-l: #FFB26B;
  --august-d: #cd01a0;

  /* Sep waxes/ Oct wanes */
  --september-l: #CDCFFF;
  --september-d: #008171;

  /* Oct waxes/ Nov wanes */
  --october-l: #FF5A27;
  --october-d: #481E00;

  /* Nov waxes/ Dec wanes */
  --november-l: #f8ffe9;
  --november-d: #FF003D;

  /* Dec waxes/ Jan wanes */
  --december-l: #FFB1BF;
  --december-d: #005c09;
}

.january.day {
  --light: var(--january-l);
  --dark: var(--january-d);
}
.january.night {
  --light: var(--january-d);
  --dark: var(--january-l);
}
.february.day {
  --light: var(--february-l);
  --dark: var(--february-d);
}
.february.night {
  --light: var(--february-d);
  --dark: var(--february-l);
}
.march.day {
  --light: var(--march-l);
  --dark: var(--march-d);
}
.march.night {
  --light: var(--march-d);
  --dark: var(--march-l);
}
.april.day {
  --light: var(--april-l);
  --dark: var(--april-d);
}
.april.night {
  --light: var(--april-d);
  --dark: var(--april-l);
}
.may.day {
  --light: var(--may-l);
  --dark: var(--may-d);
}
.may.night {
  --light: var(--may-d);
  --dark: var(--may-l);
}
.june.day {
  --light: var(--june-l);
  --dark: var(--june-d);
}
.june.night {
  --light: var(--june-d);
  --dark: var(--june-l);
}
.july.day {
  --light: var(--july-l);
  --dark: var(--july-d);
}
.july.night {
  --light: var(--july-d);
  --dark: var(--july-l);
}
.august.day {
  --light: var(--august-l);
  --dark: var(--august-d);
}
.august.night {
  --light: var(--august-d);
  --dark: var(--august-l);
}
.september.day {
  --light: var(--september-l);
  --dark: var(--september-d);
}
.september.night {
  --light: var(--september-d);
  --dark: var(--september-l);
}
.october.day {
  --light: var(--october-l);
  --dark: var(--october-d);
}
.october.night {
  --light: var(--october-d);
  --dark: var(--october-l);
}
.november.day {
  --light: var(--november-l);
  --dark: var(--november-d);
}
.november.night {
  --light: var(--november-d);
  --dark: var(--november-l);
}
.december.day {
  --light: var(--december-l);
  --dark: var(--december-d);
}
.december.night {
  --light: var(--december-d);
  --dark: var(--december-l);
}

body {
  font-size: 16px;
  font-family: "Lora", serif;
  font-weight: 500;
  line-height: 1.4;
  width: 100vw;
  overflow-x: hidden;
}

.App {background-color: var(--light);}

main,
main a {color: var(--dark);}

header {
  background-image: url("../images/baumkrone-high.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
header section {
  color: var(--light);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
header section p {
  font-size: 2rem;
}

header section p,
h2 {
  text-decoration: underline;
  text-decoration-thickness: 5px;
  text-underline-offset: 5px;
}

header section p span {
  background-color: var(--dark);
  opacity: 0.9;
}

nav,
svg#Logo,
nav ul,
section {
  box-sizing: border-box;
}

nav {
  width: 100%;
}
nav ul {
  margin: 0;
  padding: var(--padding);
  list-style-type: none;
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--padding);
  column-gap: var(--half-padding);
}
nav li {
  text-align: center;
}
nav a {
  text-decoration: none;
  color: var(--light);
  padding: 0 var(--half-padding);
  /* margin: 0 var( --half-padding) var( --padding) 0; */
  display: inline-block;
  background-color: var(--dark);
}

svg#Logo {
  width: var(--logo-width);
  max-width: calc(var(--logo-width) * 0.75);
}
svg#Logo path {
  fill: var(--light);
}
svg#Logo circle {
  fill: var(--dark);
}

section,
footer {
  padding: var(--padding);
}
footer {
  display: flex;
  background-color: var(--dark);
  color: var(--light);
  flex-direction: column;
  row-gap: var(--half-padding);
  column-gap: var(--padding);
}
footer,
footer button {
  font-size: 0.8rem;
}

footer .col.badges {
  display: flex;
  column-gap: 0.5rem;
  flex-direction: row;
}

button {
  border: 0;
  padding: 0 var(--half-padding);
  background-color: var(--light);
  color: var(--dark);
  font-family: "Lora", serif;
}
footer a {
  color: var(--light);
}
footer p {
  margin: 0;
}
footer .col:first-child {
  min-width: calc(var(--nav) - var(--half-padding));
}
/* footer .col:first-child {min-width: var(--nav)} */

#toggle {
  z-index: 11;
  position: fixed;
  right: var(--padding);
}

#menu {
  z-index: 10;
}

#mob-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 300px) {
  header section p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 735px) {
  header {
    position: relative;
  }
  nav {
    text-align: center;
  }
  nav ul {
    margin: 0 0 0 100vw;
    transition: margin 1s;
    width: 100vw;
    height: 100vh;
    background-color: var(--light);
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #mob-nav {
    padding: var(--padding);
  }
}

/* Tablet breakpoint */
@media screen and (min-width: 735px) {
  header {
    position: relative;
    flex-direction: row;
  }
  nav {
    padding-right: 0 !important;
    align-self: flex-start;
    top: 0;
  }
  svg#Logo {
    position: fixed;
  }
  section {
    /* width: calc(100% - var(--nav)); */
    width: var(--tablet-section);
    margin-left: var(--nav);
  }
  svg#Logo {
    mix-blend-mode: hard-light;
    margin-top: var(--padding);
    max-width: var(--logo-width);
    padding-left: var(--padding);
  }
  nav ul {
    /* width: calc(var(--tablet-section) - var(--padding)); */
    width: var(--tablet-section);
    /* margin-left: calc(var(--nav) + var(--padding)); */
    margin-left: var(--nav);
    /* margin-top: var(--padding); */
  }
  nav ul li {
    display: inline-block;
  }
  header section p {
    font-size: 3rem;
  }
  #toggle {
    display: none;
  }
  footer {
    flex-direction: row;
    flex-wrap: wrap;
  }
  footer .col:first-child {
    width: calc(var(--nav) - var(--half-padding));
  }
  #mode {
    text-align: right;
  }
}

/* Desktop breakpoint */
@media screen and (min-width: 1080px) {
  section {
    width: var(--desktop-section);
  }
}

nav a:hover,
button:hover,
svg#Logo:hover {
  transform: rotate(2deg);
}

/* #moon-phase {white-space: nowrap;} */

svg#NewMoon,
svg#WaxCrescent,
svg#FirstQuart,
svg#WaxGibb,
svg#FullMoon,
svg#WaneGibb,
svg#LastQuart,
svg#WaneCrescent {
  max-height: 1em;
  width: 1em;
  vertical-align: middle;
}

svg#pomologen,
svg#obstbaum {
  height: 4rem;
}

svg .light,
svg#pomologen path,
svg#obstbaum {
  fill: var(--light);
}

svg#obstbaum {
  border: solid 0.5px;
}

svg .moon {
  fill: var(--dark);
}

svg#NewMoon > .moon {
  fill: var(--light);
  opacity: 0.1;
}

#mode {
  display: block;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 2.8rem;
  height: 1.4rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dark);
  /* -webkit-transition: .4s; */
  /* transition: .4s; */
}

.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: var(--light);
  /* -webkit-transition: .4s; */
  /* transition: .4s; */
}

/* input:focus + .slider {
  box-shadow: 0 0 1px var(--dark);
} */

input:checked + .slider:before {
  -webkit-transform: translateX(1.4rem);
  -ms-transform: translateX(1.4rem);
  transform: translateX(1.4rem);
  /* background-color: var(--light); */
}
